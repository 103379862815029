import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import Rating from '../Experiences/Rating';
import { folderState, UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { GOOG_API_KEY } from '../../config';
import axios from 'axios';
import { URL } from '../../config';
import ReactGA from 'react-ga4';
import FeedImage from './FeedImage';
import LikeButton from './LikeButton';
import LikeDetails from './LikeDetails';
import { useNavigate } from 'react-router-dom';
import BookmarkButton from '../Experiences/BookmarkButton';
import InfoModal from '../Experiences/InfoModal';
import { Star } from 'lucide-react';
import Details from '../Experiences/Details';

const FeedItem = ({ action, item, username, folder, profilePic, post }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    // const [itemTime, setItemTime] = useState(item.scheduled_time);
    const [user, setUser] = useRecoilState(UserState);
    const folderSize = "md";
    const [itemDetails, setItemDetails] = useState({});
    const [folders, setFolders] = useRecoilState(folderState);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const navigate = useNavigate();
    const [newLikeCount, setNewLikeCount] = useState(0);
    const [place, setPlace] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    
    const showItemDetails = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: "Feed",
            action: "User Viewed Place Details in Feed"
        })
        axios.get(`${URL}/places/details/${item.place_id}`)
        .then(({data}) => {
            // console.log('response: ', data.result)
            setItemDetails(data.result);
            return data;
        })
        .then((data) => {
            if (!!data.result) {
                setIsModalOpen(true);
            } else {
                console.log('Error: Place not found.');
            }
            
        })
        .catch(err => {
            console.log(err)
        })

    };

    const getImageURL = () => {
        axios.get(`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY}`)
        .then(res => {
            console.log('get image response: ', res);
            return `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY}`
        })
        .catch(err => {
            console.log('Error getting image: ', err);
        })
    }
    
    const formatLocStr = (item) => {
        let strArr = [''];
        // console.log('item plus code: ', item.plus_code);
        if (!!item.plus_code && item.plus_code !== undefined) {
            if (!!item.plus_code.compound_code && item.plus_code.compound_code.includes(' ')) {
                strArr = item.plus_code ? item.plus_code.compound_code.split(' ') : [''];
            }
        } 
        let newLocStr = '';
        // console.log('Loc Str Arr: ', strArr);
        for (let i = 1; i < strArr.length; i++) {
            newLocStr += strArr[i]
            if (i < strArr.length -1) {
                newLocStr += ' ';
            }

        }
        // console.log('new location string: ', newLocStr);
        if (!!newLocStr && newLocStr.includes(', ')) {
            let newLocArr = newLocStr.split(', ');
            // console.log('new location arr: ', newLocArr);
            newLocStr = newLocArr[newLocArr.length - 2] + ', ' + newLocArr[newLocArr.length -1];
        }
        // console.log('new location string: ', newLocStr);
        // console.log('item formatted address: ', item.formatted_address);
        if (!newLocStr) {
            if (item.formatted_address.includes(', ')) {
                let strArr = item.formatted_address.split(', ');
                if (!!strArr[strArr.length -3]) {
                    newLocStr += strArr[strArr.length -3];
                    newLocStr += ', ' 
                }
                let cityArr;
                if (strArr[strArr.length -2].includes(' ') && !!strArr[strArr.length - 2]) {
                    cityArr = strArr[strArr.length -2].split(' ');
                } else {
                    cityArr = [strArr[strArr.length -2]];
                }
                // console.log('city array: ', cityArr);
                for (let i = 0; i < cityArr.length; i++) {
                    if (!Number(cityArr[i])) {
                        newLocStr += cityArr[i];
                        if (i < cityArr.length -1 && !Number(cityArr[i+1])) {
                            newLocStr += ' ';
                        }
                    }
                }
                newLocStr += ', ';
                newLocStr += strArr[strArr.length -1];
            } else {
                newLocStr = item.formatted_address;
            }
        }
        // console.log('final new location string: ', newLocStr);
        return newLocStr;
    };

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    }

    const navigateToUserProfile = () => {
        ReactGA.event({
            category: 'Feed',
            action: 'User views another users profile from feed'
        })
        navigate(`/${username.toLowerCase()}`);
    }

    const handleShow = async () => {
        ReactGA.event({
            category: 'Places',
            action: 'User Viewed Place Details'
        })
        return axios.get(`${URL}/places/details/${item.place_id}`)
        .then(({data}) => {
            // console.log('response: ', data.result)
            setPlace(data.result);
            return data;
        })
        .then((data) => {
            if (!!data.result) {
                setShowModal(true);
            } else {
                console.log('Error: Place not found.');
            }
            
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    const getDetails = async () => {
        const { data } = await axios.get(`${URL}/places/details/${item.place_id}`);
        setPlace(data.result);
    };

    const navigateToBlog = () => {
        navigate(`/album/${post.blog_id}`);
    }

    useEffect(() => {
        getDetails();
    }, [item])

    
    const newItem = { ...item, user_likes: post.user_likes };

    return(
        <Row className='feed-item'>
            <Col className={`pb-2 pt-2 mt-1 mb-2 border-bottom border-black-50 shadow`}>
                <Row>
                    {!!profilePic && (
                        <Col className='left-align mb-0 pb-0 col-md-2 col-2 pr-0'>
                            <div className='feed-profile-pic-container mr-0 pr-0' onClick={navigateToUserProfile}>
                                <Image src={`${URL}/${profilePic.path}`} loading='lazy' className='profile-image' />
                            </div>
                            {/* <Image className='mb-0 mr-0 ml-0 mt-1 p-0 d-inline feed-profile-pic' src={`${URL}/${profilePic.filename}`} onClick={navigateToUserProfile} /> */}
                        </Col>
                    )}
                    <Col className={`left-align mb-0 pb-0 ${!!profilePic ? 'pl-0' : 'pl-3'}`} style={{ marginLeft: "-0.3rem" }}>
                        <p className='font-weight-bold mb-0 pb-0 text-primary' onClick={navigateToUserProfile} style={{ fontSize: "1rem", cursor: "pointer", marginTop: "-0.3rem" }}>{username}</p>
                        <p className="ml-0 link-text mb-0 geo-text cursor-pointer" 
                            style={{ fontSize: "0.8rem", marginTop: "-0.25rem" }}
                            onClick={navigateToBlog}
                        >{folder}</p>
                    </Col>
                </Row>
                <FeedImage item={newItem} place={place} folderName={folder} folderId={post.folder_id} />
                <Row>
                    <Col className='left-align col-9 pr-1'>
                        <Details handleShow={handleShow} place={item} postView={false} />
                    </Col>
                    <Col className="col-3 pl-0 right-align d-inline">
                        {/* <Row className='m-0 p-0'>
                            <div style={{ right: "0"}}>
                                <Rating width={item.rating / 5 * 102.9} /><p className="geo-text d-inline mb-0 pb-0 ml-1">{item.rating}</p>
                            </div>
                        </Row> */}
                        <LikeButton userLikes={post.user_likes} user={user} placeId={item.place_id} postId={post.post_id} folderId={post.folder_id} setNewLikeCount={setNewLikeCount} />
                        <BookmarkButton item={item} bookMarkSize={"1.8em"} wishList={false} ownProfile={user.id === post.user_id} location={'Feed'} addAble={true} />
                        <InfoModal
                            folders={folders}
                            addAble={true}
                            wishList={false}
                            userId={user.id}
                            placeId={item.place_id}
                            place={place}
                            setPlace={setPlace}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <LikeDetails userLikes={post.user_likes} newLikeCount={newLikeCount} />
                    </Col>
                </Row>
                <Row>
                    <Col className="left-align" >
                        {!!item.notes ? 
                            <>
                                {item.notes.length > 85 ? !showFullDescription ? 
                                    <p className="mb-0 text-gray">{item.notes.slice(0,85)}... <span className='cursor-pointer text-gray show-details' onClick={toggleDescription}>(more)</span></p>
                                    : <p className="mb-0 text-gray">{item.notes} <span className='cursor-pointer text-gray show-details' onClick={toggleDescription}>(less)</span></p>
                                    : <p className="mb-0 text-gray">{item.notes}</p>
                                }
                            </>
                        : action === 'Add To Folder' ? <p className="mb-0 text-primary"><span className='font-weight-bold'>{`${username} `}</span><span className='text-gray'>{`bookmarked ${item.name}.`}</span></p>
                        : null
                        }
                        
                    </Col>
                </Row>
                {!!item.affiliate_link && (
                    <Row>
                        <Col className="left-align" >
                            <p className="mb-0 text-primary"><span className='font-weight-bold'>Book: </span><a className='text-gray' href={`${item.affiliate_link}`} target="_blank" rel="nofollow">{item.affiliate_link}</a></p>
                        </Col>
                    </Row>
                )}
            </Col>
        </Row>
    );
};

export default FeedItem;