import React from 'react';
import Place from '../Experiences/Place';
import { Container, Button, Image, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SearchModal from '../DiscoverView/SearchModal';


const FolderView = ({folder, addAble, deleteItem, myView, changeItemDate, showImg, folders, updateItemNotes, followView, blogView, updateAffiliateLink, changeItemDay, publishBlogPost, updateBlogPost, ownProfile }) => {
    // const folders = useRecoilValue(folderState);
    const navigate = useNavigate();
    
    const navigateToSearch = () => {
        navigate('/search/places');
    };

    const navigateToFeed = () => {
        navigate('/feed');
    }

    const messageIfEmpty = (followView) => {
        if (followView) {
            return "This users album is currently empty. Try looking at another album or search a different user.";
        } else {
            return "Your itinerary is currently empty. Go to the search page to add places to your trip.";   
        }
    };
    
    return (
        <Row>
        <Container className='m-0 p-0'>
            <Row className='m-0 p-0 mw-100'>
                <Col className='col-md-4 offset-md-4 col-6 offset-3 text-center'>
                    {/* {!followView && <Button className='btn-white w-50 p-2' onClick={navigateToSearch}><Image className='filter-primary w-100' src={AddIcon} /></Button>} */}
                    {!followView && !!myView && <SearchModal tripView={true} folder={folder} blogView={blogView} />}
                    {!followView && !myView && !blogView && <Button size='lg' className='btn-primary rounded-pill' onClick={()=> navigateToFeed()}>Get Inspired!</Button>}
                </Col>
            </Row>
        </Container>
        {!!folder.contents && folder.contents.length > 0 ? folder.contents.map((item, i) => (
            <Col className={(blogView || followView) ? "col-md-4 col-12 zero-mobile-padding" : "col-md-6 col-12 mw-100"} key={i}>
                <Place deleteItem={deleteItem} 
                    folders={folders} 
                    folderid={folder.id} 
                    index={i} 
                    item={item} 
                    addAble={addAble} 
                    myView={myView} 
                    changeItemDate={changeItemDate} 
                    folder={folder} 
                    showImg={showImg} 
                    wishList={folder.name === "Wishlist"} 
                    updateItemNotes={updateItemNotes} 
                    blogView={blogView} 
                    updateAffiliateLink={updateAffiliateLink} 
                    changeItemDay={changeItemDay} 
                    publishBlogPost={publishBlogPost} 
                    followView={followView} 
                    updateBlogPost={updateBlogPost} 
                    ownProfile={ownProfile}
                />
            </Col>
            ))
             : !!myView ? <h6 className='text-secondary pl-3 pr-3 mt-3'>Your itinerary is currently empty. Start adding places to get started.</h6>
            : !!blogView ? <h6 className='text-secondary pl-3 pr-3 mt-3'>Your album is currently empty. Create a new post to show others where you went.</h6>
            : !!followView ? <h6 className='text-secondary pl-3 pr-3 mt-3'>This user's album is currently empty. Try looking at another album or search a different user.</h6>
            : <h6 className='text-secondary pl-3 pr-3 mt-3'>Your folder is currently empty. Go to the home feed or follow other users to get inspired.</h6>
        }
        </Row>
    );
};

export default FolderView;