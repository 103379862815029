import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col, Button, Form, Alert, Badge, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { UserState, folderState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { URL as URL2 } from '../../config';
import { getFolders, getBlogs } from '../apiHelpers';
import axios from 'axios';
import SearchModal from '../DiscoverView/SearchModal';
import { ImagePlus } from 'lucide-react';
import LoadingGif from '../../images/loading.gif';
import { useDropzone } from 'react-dropzone';


const NewBlog = () => {
    const [tripName, setTripName] = useState('');
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [success, setSuccess] = useState(false);
    const [places, setPlaces] = useState([]);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [filePath, setFilePath] = useState(null);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const onDrop = useCallback((acceptedFiles) => {
        // console.log('Files from drop: ', acceptedFiles[0]);
        setFilePath(URL.createObjectURL(acceptedFiles[0]));
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const createMemory = async (e) => {
        setLoading(true);
        e.preventDefault();
        ReactGA.event({
            category: "Blogs",
            action: "User Creates New Blog"
        })
        if (tripName.length < 1 || file === null || places.length === 0) {
            setError(true);
            setLoading(false);
            return;
        } else {
            setError(false);
        }

        const formData = new FormData();
        const imageFile = file;
        formData.append('image', imageFile);
        formData.append('name', tripName);
        formData.append('locations', JSON.stringify(places));
        // const res = await axios.post(`${URL2}/trips/create/blog/name/${tripName}/user/${user.id}/`, formData);
        try {
            const res = await axios.post(`${URL2}/blogs/user/${user.id}/create`, formData);
            if (res.status === 201) {
                const id = res.data;
                setLoading(false);
                setSuccess(true);
                // await getFolders(user, setFolders);
                await getBlogs(user, setBlogs);
                setTimeout(() => navigate(`/profile/share`), 2100);
                setTimeout(() => setSuccess(false), 2000);
            }
            if (res.status === 500) {
                setLoading(false);
                setError(true);
            }
        } catch (err) {
            setLoading(false);
            setError(true);
        }
    }

    const handleUpload = () => {
        inputRef.current?.click();
    }

    const setImage = (e) => {
        setFile(URL.createObjectURL(inputRef.current.files[0]))
    }

    const styles = {
        dropzone: {
            width: '95%',
            height: '95%',
            border: '2px dashed white',
            borderRadius: '10px',
            textAlign: 'center',
            padding: '10px',
            margin: 'auto',
            cursor: 'pointer', // Ensures it looks clickable
            zIndex: "2"
          },
      };


    return (
        <div className='main'>
            <Container>
                <Row>
                    <Col className='col-md-6 offset-md-3 mt-3'>
                        <h4>Create an Album to start uploading photos and videos of your trip!</h4>
                        {!!loading && (
                            <div className='full-center w-75' style={{ zIndex: "3" }}>
                                <Image src={LoadingGif} className='w-100' />
                            </div>
                        )}
                        <Form>
                            <Row className='text-left mt-3 border-bottom border-black-50 shadow'>
                                <Col>
                                    <Row>
                                        <Col className='trip-cover-img position-relative' style={{ backgroundImage: filePath ? `url(${filePath})` : "none" }}>
                                            {/* {!file && (
                                                <Button className='btn-transparent full-center' onClick={handleUpload}>
                                                    <ImagePlus size={64} color='white' />
                                                    <p className='text-white'>Add Cover Photo</p>
                                                </Button>
                                            )} */}
                                                {!file && (
                                                <div className='full-center' {...getRootProps()} style={styles.dropzone}>
                                                    <input {...getInputProps()} 
                                                        accept='video/*,image/*'
                                                    />
                                                    {isDragActive ? (
                                                        <p className='text-white full-center'>Drop the files here...</p>
                                                    ) : (
                                                        <div className='full-center w-100 pr-3 pl-3'>
                                                            <ImagePlus size={100} color='white' />
                                                            <p className='text-white'>1) Drag 'n' drop a cover photo here, or click to select file</p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Col>
                                        {/* <Image className='mw-100' src={file} alt='trip background' /> */}
                                    </Row>
                                    <Row>
                                        <Col className='mt-2 mb-2'>
                                            <Form.Control placeholder='2) Name a Past Trip!' value={tripName} onChange={(e) => setTripName(e.target.value)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col>
                                <p className='mb-0'>3) Tag locations so others can discover your album!</p>
                                    <SearchModal setPlaces={setPlaces} postView={true} blogView={false} places={places} iconSize={64} />
                                    <div className='mt-3'>
                                    {places.length > 0 && places.map((place, i) => (
                                        <h4 className='d-inline mr-2' key={i}>
                                            <Badge className='p-2' variant='light'>{place.formatted_address}</Badge>
                                        </h4>
                                    ))}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-3 text-center'>
                                <Col>
                                    <Button className='rounded-pill' onClick={createMemory}>Create</Button>
                                </Col>
                            </Row>
                            {!!success && (
                                <Row className='mt-3 text-center'>
                                    <Col>
                                        <Alert variant='success'>Album Created!</Alert>
                                    </Col>
                                </Row>
                            )}
                            {!!error && (
                                <Row className='mt-3 text-center'>
                                    <Col>
                                        <Alert variant='danger'>Error: Please ensure you have a cover photo, album name, and at least one location.</Alert>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NewBlog;