import axios from 'axios';
import { URL } from '../config';
import { GOOG_API_KEY } from '../config';
import { encrypt } from 'crypto-js/aes';
import { SECRET } from '../config';


export const getUserInfo = async (email, setUserInfo) => {
    email = encrypt(JSON.stringify(email), SECRET).toString();
    const url = `${URL}/users/userinfo`;
    const res =  await axios.get(url, {params: { email }});
    const body = await res.data;
    // console.log('got user info: ', body);
    const userInfo = body.length > 0 ? body[0] : undefined;
    // console.log('saving user info as: ', userInfo);
    setUserInfo(userInfo);
    return userInfo;
};


const getPlaceData = async (placeId) => {
    const config = {
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/place/details/json`,
        headers: { },
        params: {
            key: `${GOOG_API_KEY}`,
            place_id: placeId,
            fields: "formatted_address,name,rating,photos,plus_code,place_id"
        }
      };
      try {
        let res = await axios(config);
        return res.data.result;
      } catch (err) {
        console.log('error getting places data: ', err);
      }
}

const getFolderData = async (folders) => {
                try {
                const resolveFolderPromises = async (folders) => {
                    const folderPromises = [];
                    for (const folder of folders) {
                        // console.log(folder);
                        if (folder.contents != null) {
                            try {
                                const resolvePlacesPromises = async (contents) => {
                                    let placesPromises = [];
                                    for (let place of contents) {
                                        console.log('place id: ', place.place_id);
                                        // let placeData = await getFolderItemData(place.place_id);
                                        // console.log('place data: ', placeData);
                                        let updatedPlace = await getPlaceData(place.place_id);
                                        if (!!updatedPlace) {
                                            updatedPlace.scheduled_time = place.scheduled_time;
                                            placesPromises.push(updatedPlace);
                                        } else {
                                            console.log('error: place is undefined: ', place);
                                        }

                                    }
                                    return placesPromises;
                                }
                                const contents = await resolvePlacesPromises(folder.contents);
                                // console.log('contents: ', contents);
                                folder.contents = contents;
                            } catch (err) {
                                console.log('err with places promises: ', err);
                                console.log('contents with err: ', folder.contents);
                            }
                        }
                        
                        folderPromises.push(folder);
                    }
                    const result = await Promise.all(folderPromises);
                    return result;
                } 
                const results = await resolveFolderPromises(folders);
                // console.log('final results: ', results);
                return results;
            } catch (err) {
                console.log('error getting places data: ', err);
            }
}

export const checkWishlist = async (user, folders) => {
    // if (!folders) {
    //     return;
    // }
    if (folders[0].name === "Wishlist") {
        return folders;
    }
    
    for (let i = 1; i < folders.length; i++) {
        if (folders[i].name === "Wishlist") {
            let temp = folders[0];
            folders[0] = folders[i];
            folders[i] = temp;
            return folders;
        }
    }

    console.log("no wishlist");
    let req = {
        folder: "Wishlist"
    };
    const res = await axios.post(`${URL}/users/${user.id}/folders`, req);
    let updatedFolders = await res.data;
    for (let i = 1; i < updatedFolders.length; i++) {
        // console.log('current folder: ', updatedFolders[i])
        if (updatedFolders[i].name === "Wishlist") {
            let temp = updatedFolders[0];
            updatedFolders[0] = updatedFolders[i];
            updatedFolders[i] = temp;
            return updatedFolders;
        }
    }
}

export const getFolders = async (user, setFolders) => {
    // console.log('Getting folders: ', user.id);
    const url = `${URL}/users/${user.id}/folders`;
    const res = await axios.get(url);
    const body = await res.data;
    // console.log('folders: ', body);
    // const folders = await getFolderData(body)
    // console.log('updated folders: ', folders);
    let folders = await checkWishlist(user, body);
    // console.log('folders: ', folders);
    setFolders(folders);
    return folders;
};

const shuffle =(array) => {
    for (let i = array.length -1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

export const getNewsFeed = async (userInfo, setNewsFeed) => {
    // const url = `${URL}/feed/user/${userInfo.id}`;
    // const res = await axios.get(url);
    // const body = await res.data;
    // console.log('News Feed data: ', body);
    const url = `${URL}/feed/count/12/offset/0`;
    const res = await axios.get(url);
    const body = res.data;
    // console.log('feed data: ', body);
    setNewsFeed(body);
};

export const getBlogs = async (userInfo, setBlogs) => {
    const url = `${URL}/blogs/user/${userInfo.id}`;
    const res = await axios.get(url);
    const body = res.data;
    // console.log('Blogs: ', body);
    setBlogs(body);
}