import React, { useState, useEffect } from 'react';
import { URL } from '../../config';
import { GOOG_API_KEY } from '../../config';
import { Row, Col, Image } from 'react-bootstrap';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import PostPopup from '../Experiences/PostPopup';
import axios from 'axios';


const FeedImage = ({ item, folderName, folderId }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isVideo, setIsVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [place, setPlace] = useState();

    const getCurrentPhoto = (photoIndex) => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            
            const fileType = item.user_photos[photoIndex].filename.split('.')[item.user_photos[photoIndex].filename.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4') {
                // setIsVideo(true);
                return `${URL}/${item.user_photos[photoIndex].path}#t=0.001`;
            } else {
                // setIsVideo(false);
                return `${URL}/${item.user_photos[photoIndex].path}`;
            }
        } else {
            // setIsVideo(false);
            return '';
        }
    }

    useEffect(() => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            // console.log('path: ', item.user_photos[photoIndex].path);
            const fileType = item.user_photos[photoIndex].filename.split('.')[item.user_photos[photoIndex].filename.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4') {
                setVideoUrl(`${URL}/${item.user_photos[photoIndex].path}#t=0.001`);
                setIsVideo(true);
            } else {
                setIsVideo(false);
            }
        }
    }, [photoIndex, item])

    const handlePhotoClick = () => {
        if (!isMobile) {
          setShowModal(true);
        }
    };

    const getDetails = async () => {
        const { data } = await axios.get(`${URL}/places/details/${item.place_id}`);
        setPlace(data.result);
    };

    useEffect(() => {
        if (!item.user_photos || item.user_photos.length === 0) {
            getDetails();
        }
    }, [item])

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Assuming devices with width <= 768px are mobile
        };
    
        handleResize(); // Set the initial value
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    return (
        <>
        {!!item.user_photos && item.user_photos.length > 0 && (
                <Row className="mb-1 mt-1 cursor-pointer" style={{backgroundImage: "none"}}>
                    <Col className='feed-img-container'>
                    {!isVideo && <Image src={getCurrentPhoto(photoIndex)} loading='lazy' className="feed-img" onClick={handlePhotoClick} />}
                    {!!isVideo && (
                            <video key={videoUrl} className='feed-img' controls muted playsInline preload='metadata' loading="lazy" onClick={handlePhotoClick}>
                                <source src={getCurrentPhoto(photoIndex)} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    )}
                    {photoIndex < item.user_photos.length -1 && (
                            <ChevronRight
                                onClick={() => {
                                    setPhotoIndex(index => index + 1);
                                }}
                                size={40}
                                color='white'
                                className='right-arrow mr-2'
                                style={{ zIndex: "3" }}
                            />
                    )}
                    {photoIndex > 0 && (
                            <ChevronLeft
                                onClick={() => {
                                    setPhotoIndex(index => index - 1)
                                }}
                                size={40}
                                color='white'
                                className='left-arrow ml-2'
                                style={{ zIndex: "3"}}
                            />
                    )}
                    </Col>
                </Row> 
            )}
            {!!place && !!place.photos && (
                <Row className="mb-1 mt-1 cursor-pointer" style={{backgroundImage: "none"}}>
                <Col className='feed-img-container'>
                <Image src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${place.photos[photoIndex].photo_reference}&key=${GOOG_API_KEY}`} className="feed-img pl-0 pr-0 mr-0 ml-0" />
                {photoIndex < place.photos.length -1 && (
                    <ChevronRight
                        onClick={() => {
                            setPhotoIndex(index => index + 1);
                        }}
                        size={40}
                        color='white'
                        className='right-arrow mr-2'
                        style={{ zIndex: "3" }}
                    />
                )}
                {photoIndex > 0 && (
                    <ChevronLeft
                        onClick={() => {
                            setPhotoIndex(index => index - 1)
                        }}
                        size={40}
                        color='white'
                        className='left-arrow ml-2'
                        style={{ zIndex: "3"}}
                    />
                )}
            </Col>
        </Row>
        )}
        <PostPopup item={item} showModal={showModal} setShowModal={setShowModal} folderName={folderName} folderId={folderId} />
    </>
    )
}

export default FeedImage;