import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { UserState, newsFeedState, folderState } from '../Recoil/atoms';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getNewsFeed } from '../apiHelpers';
import SignUpForm from '../HomePage/SignUpForm';
import FeedItem from './FeedItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { URL } from '../../config';
import axios from 'axios';


const Feed = () => {
    const [newsFeedData, setNewsFeedData] = useRecoilState(newsFeedState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        if (!!userInfo) {
            getNewsFeed(userInfo, setNewsFeedData);
        }
    },[userInfo, location]);

    const getMorePosts = async (e) => {
        e.preventDefault();
        const url = `${URL}/feed/count/12/offset/${newsFeedData.length}`;
        const res = await axios.get(url);
        const newFeedData = res.data;
        let updatedFeedData = [...newsFeedData, ...newFeedData];
        setNewsFeedData(updatedFeedData);
    }

    return (
        <>
        {userInfo === undefined && (
            <SignUpForm />
        )}
        {!!userInfo && (
            <div className='main'>
                <Container>
                    <Row>
                        {!!newsFeedData && newsFeedData.map((post, i) => {
                            return (
                                <Col className='col-md-4 col-12' key={i}>
                                    <FeedItem post={post} item={post} username={post.username} folder={post.blog_name} profilePic={post.profile_image} />
                                </Col>
                            )
                        })}
                    </Row>
                    <Row className='mt-3 mb-3'>
                        <Col>
                            <Button onClick={getMorePosts} className='btn-primary rounded-pill'>More Posts...</Button>
                        </Col>
                    </Row>
                </Container>
                {!!newsFeedData && newsFeedData.length === 0 && (
                    <Container className='col-md-8 offset-md-2'>
                        <h6 className='text-secondary mt-5'>You currently have nothing in your feed. Follow more users to see their posts!</h6>
                        <Button className='btn-primary rounded-pill mt-3' onClick={() => navigate('/search/users')}>Follow</Button>
                    </Container>
                )}
            </div>
        )}
        </>
    );
};

export default Feed;