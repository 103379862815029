import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { UserState } from '../Recoil/atoms';


const LikeDetails = ({ userLikes, newLikeCount }) => {
    const likeCount = !!userLikes && userLikes.length > 0 ? userLikes.length + newLikeCount : false;
    const user = useRecoilValue(UserState);

    return (
        <Row className='pr-3 pl-0 mb-0 pb-0 mr-0 ml-0 mw-100'>
            <Col className='left-align pl-0 mb-0 pb-0'>
                {!!likeCount && <p className='text-dark mb-0 pb-0'>Liked by <span className='font-weight-bold'>{userLikes[0].username}</span>{likeCount > 1 && ' and '}<span className='font-weight-bold'>{likeCount > 2 ? `${likeCount -1 } others` : likeCount > 1 ? 'one other' : null}</span></p>}
                {!!user && !likeCount && newLikeCount === 1 && <p className='text-dark mb-0 pb-0'>Liked by <span className='font-weight-bold'>{user.username}</span></p>}
            </Col>
        </Row>
    )
}

export default LikeDetails;