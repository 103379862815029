import React, { useState, useEffect, Suspense } from 'react';
import {
    Route,
    Routes,
    useNavigate,
    useLocation
} from 'react-router-dom';
import Trips from './components/Trips/Trips.js';
import Follow from './components/FollowView/Follow.js';
import UserAccount from './components/AccountView/UserAccount.js';
import  { withCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { folderState, UserState, newsFeedState, blogState } from './components/Recoil/atoms';
import { getFolders } from './components/apiHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import NavBar from './components/NavBar';
import { getUserInfo, getBlogs } from './components/apiHelpers';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import HomePage from './components/HomePage/HomePage.js';
import ReactGA from 'react-ga4';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js';
import TermsOfService from './components/TermsOfService/TermsOfService.js';
import UserView from './components/FollowView/UserView.js';
import Notifications from './components/Notifications/Notifications.js';
import MobileHeader from './components/MobileHeader.js';
import SignUpForm from './components/HomePage/SignUpForm.js';
import Search from './components/DiscoverView/Search.js';
import NewBlog from './components/NewBlog/NewBlog.js';
import NewPost from './components/NewPost/NewPost.js';
// const Feed = React.lazy(() => import('./components/Feed/Feed.js'));
import Feed from './components/Feed/Feed.js';
import Loading from './components/Loading.js';
// const BlogView = React.lazy(() => import('./components/Blogs/BlogView.js'));
import BlogView from './components/Blogs/BlogView.js';



const ProtectedFeed = withAuthenticationRequired(Feed);
const ProtectedTrips = withAuthenticationRequired(Trips);
const ProtectedFollow = withAuthenticationRequired(Follow);
const ProtectedUserAccount = withAuthenticationRequired(UserAccount);
const ProtectedUserView = withAuthenticationRequired(UserView);
const ProtectedNotifications = withAuthenticationRequired(Notifications);
const ProtectedSignUpForm = withAuthenticationRequired(SignUpForm);
const ProtectedSearch = withAuthenticationRequired(Search);
const ProtectedNewBlog = withAuthenticationRequired(NewBlog);
const ProtectedBlogView = withAuthenticationRequired(BlogView);
const ProtectedNewPost = withAuthenticationRequired(NewPost);


const App = () => {
    const [folders, setFolders] = useRecoilState(folderState);
    const [userInfo, setUserInfo] = useRecoilState(UserState);
    const [newsFeedData, setNewsFeedData] = useRecoilState(newsFeedState);
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [notifications, setNotifications] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [prevPath, setPrevPath] = useState('/');
    const [blogs, setBlogs] = useRecoilState(blogState);


    const getUserData = async () => {
        const userInfo = await getUserInfo(user.email, setUserInfo);
        // console.log('user info: ', userInfo);
        if (!!userInfo) {
            getFolders(userInfo, setFolders);
            getBlogs(userInfo, setBlogs);
        }
        if (userInfo && !!userInfo.pending_followers) {
            const newNotifications = Object.keys(userInfo.pending_followers).length;
            setNotifications(newNotifications);
        }
    }

    useEffect(() => {
        if (isAuthenticated && !!user) {
            getUserData();
            // navigate('/feed');
        }
    }, [user]);


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname})
        if (prevPath === '/notifications') {
            getUserData();
        }
        setPrevPath(location.pathname);
    }, [location])

    useEffect(() => {
        if (!userInfo && !!user) {
            navigate('/signup');
        } else if (!!userInfo && location.pathname === '/signup') {
            navigate('/feed');
        }
    }, [userInfo])

    return (
            <div id="app" className="main">
            {isAuthenticated && userInfo && <NavBar notifications={notifications} /> }
            {isAuthenticated && userInfo && <MobileHeader />}
            <div className='mobile-padding'>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/signup" element={<ProtectedSignUpForm />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/feed" element={<ProtectedFeed />} />
                    <Route path="/profile/plan" element={<ProtectedTrips />} />
                    <Route path="/profile/share" element={<ProtectedTrips />} />
                    <Route path="/profile/dream" element={<ProtectedTrips />} />
                    <Route path="/search/places" element={<ProtectedSearch />} />
                    <Route path='/search/users' element={<ProtectedSearch />} />
                    <Route path='/search/albums' element={<ProtectedSearch />} />
                    <Route path="/follow" element={<ProtectedFollow />} />
                    <Route path="/settings" element={<ProtectedUserAccount />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/:username" element={<ProtectedUserView />} />
                    <Route path="/notifications" element={<ProtectedNotifications setNotifications={setNotifications} />} />
                    <Route path='/post' element={<ProtectedNewBlog />} />
                    <Route path='/album/:id' element={<ProtectedBlogView />} />
                    <Route path='/post/memory/:blogid/:name' element={<ProtectedNewPost />} />
                </Routes>
            </Suspense>
            </div>
            {/* <Footer /> */}
            </div>

    );
};

export default App;