import React, { useState, useEffect } from 'react';
import useModal from './useModal';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Rating from './Rating';
import { UserState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import SelectTime from './SelectTime';
import axios from 'axios';
import { URL } from '../../config';
import ReactGA from 'react-ga4';
import ItemDay from './ItemDay';
import PublishButton from './PublishButton';
import Notes from './Notes';
import AffiliateLink from './AffiliateLink';
import ItemPhoto from './ItemPhoto';
import LikeButton from '../Feed/LikeButton';
import LikeDetails from '../Feed/LikeDetails';
import BookmarkButton from './BookmarkButton';
import InfoModal from './InfoModal';
import { Trash } from 'lucide-react';
import Details from './Details';
import { useNavigate } from 'react-router-dom';

const Place = ({ item, addAble, index, folders, folderid, deleteItem, myView, changeItemDate, folder, followView, wishList, updateItemNotes, blogView, updateAffiliateLink, changeItemDay, publishBlogPost, searchView, updateBlogPost, ownProfile, postView, setPostPlace, folderName }) => {
    const { isShowing, toggle, showDetails, toggleDetails } = useModal();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = () => setIsModalOpen(false);
    // const [itemTime, setItemTime] = useState(item.scheduled_time);
    const [user, setUser] = useRecoilState(UserState);
    const folderSize = "md";
    const [itemDetails, setItemDetails] = useState({});
    const [newLikeCount, setNewLikeCount] = useState(0);
    const [place, setPlace] = useState({});
    const [showModal, setShowModal] = useState(false);
    
    const showItemDetails = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Places',
            action: 'User Viewed Place Details'
        })
        return axios.get(`${URL}/places/details/${item.place_id}`)
        .then(({data}) => {
            // console.log('response: ', data.result)
            setItemDetails(data.result);
            return data;
        })
        .then((data) => {
            if (!!data.result) {
                setIsModalOpen(true);
            } else {
                console.log('Error: Place not found.');
            }
            
        })
        .catch(err => {
            console.log(err)
        })

    };
    
    const formatLocStr = (item) => {
        let strArr = [''];
        // console.log('item plus code: ', item.plus_code);
        if (!!item.plus_code && item.plus_code !== undefined) {
            if (!!item.plus_code.compound_code && item.plus_code.compound_code.includes(' ')) {
                strArr = item.plus_code ? item.plus_code.compound_code.split(' ') : [''];
            }
        } 
        let newLocStr = '';
        // console.log('Loc Str Arr: ', strArr);
        for (let i = 1; i < strArr.length; i++) {
            newLocStr += strArr[i]
            if (i < strArr.length -1) {
                newLocStr += ' ';
            }

        }
        // console.log('new location string: ', newLocStr);
        if (!!newLocStr && newLocStr.includes(', ')) {
            let newLocArr = newLocStr.split(', ');
            // console.log('new location arr: ', newLocArr);
            newLocStr = newLocArr[newLocArr.length - 2] + ', ' + newLocArr[newLocArr.length -1];
        }
        // console.log('new location string: ', newLocStr);
        // console.log('item formatted address: ', item.formatted_address);
        if (!newLocStr) {
            if (item.formatted_address.includes(', ')) {
                let strArr = item.formatted_address.split(', ');
                if (!!strArr[strArr.length -3]) {
                    newLocStr += strArr[strArr.length -3];
                    newLocStr += ', ' 
                }
                let cityArr;
                if (strArr[strArr.length -2].includes(' ') && !!strArr[strArr.length - 2]) {
                    cityArr = strArr[strArr.length -2].split(' ');
                } else {
                    cityArr = [strArr[strArr.length -2]];
                }
                // console.log('city array: ', cityArr);
                for (let i = 0; i < cityArr.length; i++) {
                    if (!Number(cityArr[i])) {
                        newLocStr += cityArr[i];
                        if (i < cityArr.length -1 && !Number(cityArr[i+1])) {
                            newLocStr += ' ';
                        }
                    }
                }
                newLocStr += ', ';
                newLocStr += strArr[strArr.length -1];
            } else {
                newLocStr = item.formatted_address;
            }
        }
        // console.log('final new location string: ', newLocStr);
        return newLocStr;
    };

    const bookMarkLocation = () => {
        if (!!searchView) {
            return 'Search'
        }
        if (!!followView) {
            return 'Follow'
        }
        if (!!wishList) {
            return 'Wishlist'
        }
    }

    const handleShow = async () => {
        ReactGA.event({
            category: 'Places',
            action: 'User Viewed Place Details'
        })
        return axios.get(`${URL}/places/details/${item.place_id}`)
        .then(({data}) => {
            // console.log('response: ', data.result)
            setPlace(data.result);
            return data;
        })
        .then((data) => {
            if (!!data.result) {
                setShowModal(true);
            } else {
                console.log('Error: Place not found.');
            }
            
        })
        .catch(err => {
            console.log(err)
        })
    };

    const handleSetPlace = (e) => {
        e.preventDefault();
        setPostPlace(item);
    }

    const getDetails = async () => {
        const { data } = await axios.get(`${URL}/places/details/${item.place_id}`);
        setPlace(data.result);
    };

    // useEffect(() => {
    //     getDetails();
    // }, [item])

    const navigate = useNavigate();

    const navigateToBlog = () => {
        if (!!folderid) {
            navigate(`/album/${folderid}`);
        }
    }

    return(
        <div className='m-0 p-0 mw-100'>
        <Container className={ !!searchView ? "col-md-8 offset-md-2 pb-2 pt-2 mt-1 mb-2 border-bottom border-black-50 shadow" : !!postView ? "col-md-12 pr-0 pl-0 pb-2 pt-2 mt-1 mb-2 border-bottom border-black-50 shadow" : "pr-0 pl-0 pb-2 pt-2 mt-1 mb-2 border-bottom border-black-50 shadow"}>
            {!!postView && !searchView && (
                <Row className='mt-1 mb-2 pr-3 pl-0 mr-0 ml-0 mw-100'>
                    {!!user.profile_image && (
                        <Col className='left-align mb-0 pb-0 col-md-1 col-2 pr-0'>
                            <div className='feed-profile-pic mr-0 pr-0' style={{ backgroundImage: `url(${URL}/${user.profile_image.path})` }}></div>
                            {/* <Image className='mb-0 mr-0 ml-0 mt-1 p-0 d-inline feed-profile-pic' src={`${URL}/${profilePic.filename}`} onClick={navigateToUserProfile} /> */}
                        </Col>
                    )}
                    <Col className={`left-align mb-0 pb-0 ${!!user.profile_image ? 'pl-0' : 'pl-3'}`}>
                        <p className='font-weight-bold mb-0 pb-0 text-primary' style={{ fontSize: "1rem", cursor: "pointer", marginTop: "-0.3rem" }}>{user.username}</p>
                        <p className="ml-0 mb-0 geo-text" style={{ fontSize: "0.8rem", marginTop: "-0.3rem", cursor: !!folderid && "pointer" }} onClick={navigateToBlog}>{folderName}</p>
                    </Col>
                </Row>
            )}
            {!!myView && folder.name != "Wishlist" ? 
                <div className='p-0 m-0'>
                    <SelectTime item={item} changeItemDate={changeItemDate} index={index} folderid={folderid} folder={folder} />
                </div>
            : null }
            {!postView && (
                <div className='pr-3 pl-3'>
                    <ItemDay blogView={blogView} item={item} changeItemDay={changeItemDay} index={index} folderid={folderid} followView={followView} />
                </div>
            )}
            <ItemPhoto 
                item={item} 
                index={index} 
                folderid={folderid} 
                blogView={blogView} 
                showModal={showModal}
                setShowModal={setShowModal}
                placeId={item.place_id}
                setPlace={setPlace}
                handleShow={handleShow}
                postView={postView}
                folderName={!!folder ? folder.name : folderName}
                folderId={folderid}
            />
            <Row className="pr-3 pl-3 pb-0 m-0">
                <Col className="left-align pl-0 col-9 col-xs-6" >
                    <Details place={item} handleShow={handleShow} />
                </Col>
                <Col className="col-3 col-xs-6 ml-0 pl-2 pr-0 right-align d-inline">
                    {(!!followView || !!blogView) && (
                        <LikeButton 
                            userLikes={item.post_likes} 
                            user={user} 
                            folderId={folderid} 
                            placeId={item.place_id} 
                            postId={item.id} 
                            setNewLikeCount={setNewLikeCount} 
                        />
                    )}
                    {/* {(!!addAble || wishList) && (
                        <AddToFolder bookMarkSize={"1.8em"} item={item} folders={folders} wishList={wishList} ownProfile={ownProfile} />
                    )} */}
                    {!postView && (
                        <BookmarkButton bookMarkSize={"1.8em"} item={item} folders={folders} wishList={wishList} ownProfile={ownProfile} location={bookMarkLocation()} addAble={addAble} folder={folder} searchView={searchView} />
                    )}
                    {(!addAble || wishList) && !postView && !followView && (
                        <Button size="md" className="btn-white ml-2 mb-0 p-0 mt-1" style={{bottom: "0"}} onClick={e => deleteItem(item.id)}>
                            {/* <Image className="m-0 filter-danger" style={{ height: "1.8em",  }} src={TrashIcon} /> */}
                            <Trash color='#424242' size={30} />
                        </Button>
                    )}
                    {!!postView && !!searchView && (
                        <Button className='btn-secondary' onClick={handleSetPlace}>Select</Button>
                    )}
                    <InfoModal
                        folders={folders}
                        addAble={addAble}
                        wishList={wishList}
                        folderid={folderid}
                        deleteItem={deleteItem}
                        userId={user.id}
                        index={index}
                        placeId={item.place_id}
                        place={place}
                        setPlace={setPlace}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        folder={folder}
                        searchView={searchView}
                    />
                </Col>
            </Row>
            {(!!followView || !!blogView) && (
                <div className='pl-3'>
                    <LikeDetails userLikes={item.post_likes} newLikeCount={newLikeCount} />
                </div>
            )}
            <div className='pr-3 pl-3'>
                <Notes myView={myView} blogView={blogView} followView={followView} folder={folder} item={item} updateItemNotes={updateItemNotes} folders={folders} index={index} postView={postView} folderId={folderid} />
                <AffiliateLink blogView={blogView} followView={followView} item={item} folder={folder} updateAffiliateLink={updateAffiliateLink} folders={folders} index={index} postView={postView} folderId={folderid} />
                {/* <PublishButton blogView={blogView} item={item} user={user} folder={folder} publishBlogPost={publishBlogPost} index={index} updateBlogPost={updateBlogPost} postView={postView} folderId={folderid} /> */}
            </div>
        </Container>
        </div>
    );
};

export default Place;