import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const LoginButton = () => {
  const { loginWithRedirect, loginWithPopup } = useAuth0();

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    // e.preventDefault();
    ReactGA.event({
      category: "User Accounts",
      action: "User Logs In"
    })
    // await loginWithRedirect({
    //   appState: {
    //     returnTo: "/feed"
    //   },
    // });
    await loginWithPopup();
  }

  return (
    <Button
      className="btn-transparent login-text"
      size="lg"
      onClick={handleLogin}
    >
      Login
    </Button>
  );
};

export default LoginButton;