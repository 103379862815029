import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import SearchModal from '../DiscoverView/SearchModal';
import axios from 'axios';
import { URL } from '../../config';
import { getFolders, getBlogs } from '../apiHelpers';
import { folderState, UserState, blogState } from '../Recoil/atoms';
import { useRecoilState } from 'recoil';
import { X } from 'lucide-react';


const Locations = ({ blog, ownProfile }) => {
    const [user, setUser] = useRecoilState(UserState);
    const [folders, setFolders] = useRecoilState(folderState);
    const [blogs, setBlogs] = useRecoilState(blogState);

    const addNewLocation = async (location) => {
        const res = await axios.post(`${URL}/blog/${blog.id}/location`, { location });
        if (res.status === 201) {
            getBlogs(user, setBlogs);
        }
    };

    const deleteLocation = async (location) => {
        const res = await axios.delete(`${URL}/blog/${blog.id}/location`, { params: { location }});
        if (res.status === 200) {
            getBlogs(user, setBlogs);
        }
    }

    return (
        <Row className='mt-1 mw-100 mt-2'>
            <Col className='text-left col-md-9 col-7'>
                <div className='text-wrap'>
                {!!blog.locations && blog.locations.length > 0 && blog.locations.map((location, i) => (
                    <h5 className='mr-2 d-inline' key={i}>
                        <Badge className='mb-1' key={i} variant='light'>{location.location} {ownProfile && <X className='cursor-pointer' onClick={() => deleteLocation(location.location)} color='black' size={25} />}</Badge>
                    </h5>
                ))}
                </div>
            </Col>
            {ownProfile && (
                <Col className='text-right col-md-3 col-5 mt-0 mr-0 pr-0 d-inline-block'>
                    <p className='d-inline-block mb-0 mr-1'>Add Locations</p>
                    <div className='d-inline-block'>
                        <SearchModal iconSize={40} postView={true} setPlace={addNewLocation} />
                    </div>
                </Col>
            )}
        </Row>
    )
};

export default Locations;