import React, { useState } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AUTH0_DOMAIN, AUTH0_CLIENTID, CALLBACK_URL} from '../../config';

const Auth0ProviderWithNavigate = ({ children, ...props }) => {
    const { isAuthenticated } = useAuth0();

    const navigate = useNavigate();
    const onRedirectCallback = (appState) => {
        // Use navigate and provide a clear fallback (e.g., homepage)
        // console.log('On redirect callback...');
        const returnTo = appState?.returnTo || '/feed';
        navigate(returnTo);
    };


    
    return (
        <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENTID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>

    )
};

export default Auth0ProviderWithNavigate;